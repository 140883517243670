import { Menu } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import router, { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LabelLink } from 'src/components/global/LabelLink'
import {
  useGetShareableContentsLazyQuery,
  useMySharedContentsLazyQuery,
  useSolutionsForVendorLazyQuery,
} from 'src/graphql/generated/hooks'
import { AppState } from 'src/store/types'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { data } from 'tailwindcss/defaultTheme'

import { VendorState } from '../EditListing/VendorStateModal'
interface AccountMenuProps {
  isOpen: boolean
  handleSignout: () => void
  isPremium?: boolean
  isVendor?: boolean
}

const FirstComponent = ({
  data,
  //authInfo,
  menuOpen,
  //setMenu,
  handleSignout,
  isPremium,
  isVendor,
  isEnhanced,
  isResourceExist,
  solutionsCount,
  isSharedResourceExist,
  setVendorState,
}: {
  data: any
  //authInfo: any
  menuOpen: boolean
  //setMenu: any
  handleSignout: () => void
  isPremium?: boolean
  isVendor?: boolean
  isEnhanced?: boolean
  isResourceExist?: boolean
  solutionsCount: number
  isSharedResourceExist?: boolean
  setVendorState: (item: boolean) => void
}) => {
  const authInfo = useSelector((state: AppState) => state.authInfo)
  const handleSignoutAndRedirect = () => {
    handleSignout()
  }

  return (
    menuOpen && (
      <Menu.Items
        static
        className="z-30 flex flex-row w-full  rounded-[10px] shadow-lg bg-white absolute"
        style={{ left: '-65px', top: '40px', width: 'auto' }}
      >
        <div className="py-2 w-full">
          <ul>
            {isVendor &&
              authInfo?.vendor != '' &&
              authInfo?.vendor != null &&
              (solutionsCount > 0 && authInfo.publishedAt !== null ? (
                <li className="px-2 w-full">
                  <LabelLink
                    href={'/my-solutions'}
                    className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                    label="Manage Solutions"
                    onClick={(e) => {
                      if (data && authInfo.publishedAt === '') {
                        e.preventDefault()
                        setVendorState(true)
                      }
                    }}
                  />
                </li>
              ) : (
                <li className="px-2 w-full">
                  <LabelLink
                    href={'/create-new-solution'}
                    className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                    label="Add Solution"
                    onClick={(e) => {
                      if (data && authInfo.publishedAt === '') {
                        e.preventDefault()
                        setVendorState(true)
                      } else {
                        router.push('/create-new-solution')
                      }
                    }}
                  />
                </li>
              ))}
            <li className="px-2 w-full">
              <LabelLink
                href={'/my-followings'}
                className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                label="Followed Solutions"
              />
            </li>
            {isVendor && (isPremium || isEnhanced) && isResourceExist && (
              <li className="px-2 w-full">
                <LabelLink
                  href={'/my-resources'}
                  className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                  label="My Resources"
                />
              </li>
            )}
            {isVendor && !(isPremium || isEnhanced) && isSharedResourceExist && (
              <li className="px-2 w-full">
                <LabelLink
                  href={'/user-resources'}
                  className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                  label="My Resources"
                />
              </li>
            )}
            {!isVendor && isSharedResourceExist && (
              <li className="px-2 w-full">
                <LabelLink
                  href={'/user-resources'}
                  className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                  label="My Resources"
                />
              </li>
            )}
            <li className="px-2 w-full">
              <LabelLink
                href={'/my-account'}
                className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                label="My Profile"
              />
            </li>
            <li className="px-2 w-full">
              <button
                className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                onClick={handleSignoutAndRedirect}
              >
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      </Menu.Items>
    )
  )
}

/**
 * Primary UI component for user interaction
 */
export const AccountMenu: React.FC<AccountMenuProps> = ({
  isOpen,
  handleSignout,
  isVendor = false,
  isPremium = false,
}) => {
  const authInfo = useSelector((state: AppState) => state.authInfo)
  const [isEnhanced, setIsEnhanced] = useState(false)
  const [loadData, { data }] = useSolutionsForVendorLazyQuery()
  const [solutionsCount, setSolutionsCount] = useState(0)
  const [loadContents, { data: contentsData }] = useGetShareableContentsLazyQuery()
  const [loadSharedContents, { data: sharedContentsData }] = useMySharedContentsLazyQuery()
  const [isResourceExist, setIsResourceExist] = useState(false)
  const [isSharedResourceExist, setIsSharedResourceExist] = useState(false)
  const router = useRouter()
  const [showVendorState, setVendorState] = useState(false)
  const [menuOpen, setMenuOpen] = useState(isOpen)
  useEffect(() => {
    if (!router.isReady) {
      return
    }

    if (!authInfo.idToken || authInfo.idToken === '') {
      return
    }
    loadData({
      variables: {
        query: {
          offset: 0,
          limit: 1000,
        },
      },
    })
  }, [router.isReady, router.query, authInfo.idToken])

  useEffect(() => {
    if (data?.searchVendorSolutions.solutions) {
      setSolutionsCount(data.searchVendorSolutions.solutions.length)
      data.searchVendorSolutions.solutions.map((item) => {
        if (item?.isEnhanced) {
          setIsEnhanced(true)
        }
      })
    }
  }, [data])

  /* const handleSignoutAndRedirect = () => {
    handleSignout()
    router.push('/')
  } */

  useEffect(() => {
    const tempQuery = {
      subTopicIds: '',
      contentType: '',
    }
    loadContents({ variables: { offset: 0, limit: 1, query: tempQuery } })
    if (contentsData) {
      const total = contentsData?.shareableContents?.total || 0
      if (total > 0) {
        setIsResourceExist(true)
      }
    }
  }, [contentsData])

  useEffect(() => {
    const tempQuery = {
      subTopicIds: '',
      contentType: '',
    }
    loadSharedContents({ variables: { offset: 0, limit: 1, query: tempQuery } })
    if (sharedContentsData) {
      const total = sharedContentsData?.mySharedContents?.total || 0
      if (total > 0) {
        setIsSharedResourceExist(true)
      }
    }
  }, [sharedContentsData])

  /* const setMenu = (status: any) => {
    setMenuOpen(status)
  } */

  useEffect(() => {
    // eslint-disable-next-line no-console
    setMenuOpen(isOpen)
  }, [isOpen])

  return (
    <>
      <Menu as="div" className={cn('relative inline-block h-full text-nowrap cursor-pointer text-lef ml-3')}>
        {({ open }) => (
          <>
            <Menu.Button
              type="button"
              className={cn(
                'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
                { 'bg-white bg-opacity-5': open },
              )}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              My Account
              <IonIcon
                className="text-white mr-0 !important"
                name={menuOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
              />
            </Menu.Button>
            <FirstComponent
              data={data}
              menuOpen={menuOpen}
              //setMenu={setMenu}
              handleSignout={handleSignout}
              isVendor={isVendor}
              isPremium={isPremium}
              isEnhanced={isEnhanced}
              isResourceExist={isResourceExist}
              solutionsCount={solutionsCount}
              isSharedResourceExist={isSharedResourceExist}
              setVendorState={setVendorState}
            />
          </>
        )}
      </Menu>
      {showVendorState && (
        <VendorState
          isOpen={showVendorState}
          onClose={() => setVendorState(false)}
          vendorName={authInfo.vendor || null}
        />
      )}
    </>
  )
}
